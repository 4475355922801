











import { Component, Prop, Vue } from 'vue-property-decorator';
// import api from '@/sdk/api/Api.ts';
@Component({
  components: {
    InsBanner: () => import('@/components/base/pc/InsBanner.vue'),
    CmxAboutUs: () => import('@/components/cmxBusiness/pc/home/CmxAboutUs.vue'),
    CmxCateList: () => import('@/components/cmxBusiness/pc/home/CmxCateList.vue'),
    CmxNewsList: () => import('@/components/cmxBusiness/pc/home/CmxNewsList.vue'),
    CmxMap: () => import('@/components/cmxBusiness/pc/home/CmxMap.vue'),
    CmxPopUp: () => import('@/components/cmxBusiness/pc/home/CmxPopUp.vue')
  }
})
export default class InsHome extends Vue {
  showPopUP:boolean=false;
  swiperOption: object = {
    loop: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };
  agree () {
    this.showPopUP = false;
    window.localStorage.setItem('storge', 'true');
    document.body.style.overflowY = 'auto';
  }
  decline () {
    window.location.href = 'about:blank';
    window.close();
  }
  mounted () {
    // api
    //   .getData(1, 1)
    //   .then((res: any) => {
    //     var _this = this;
    //     this.$HiddenLayer();
    //   })
    //   .catch((err: any) => {
    //     // 请求失败后的处理函数
    //     console.log(err);
    //   });
    this.$HiddenLayer();
    if (!window.localStorage.getItem('storge')) {
      let _this = this;
      _this.$nextTick(() => {
        _this.showPopUP = true;
      });
      setTimeout(() => {
        if (_this.showPopUP) {
          document.body.style.height = '100vh';
          document.body.style.overflowY = 'hidden';
        }
      }, 2000);
    }
  }
  created () {
    let keywords = document.createElement('meta');
    keywords.setAttribute('name', 'keywords');
    keywords.setAttribute('content', require('../../sdk/common/SeoData').seoItem['home'].keyword);
    document.head.appendChild(keywords);
    let description = document.createElement('meta');
    description.setAttribute('name', 'description');
    description.setAttribute('content', require('../../sdk/common/SeoData').seoItem['home'].description);
    document.head.appendChild(description);
    document.dispatchEvent(new Event('render-event'));
  }
}
